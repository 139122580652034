.fleet_vehicle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px;

  .fleet_vehicle_YMM {
    margin: 0;
    color: #282828;
    font-weight: 700;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  .fleet_vehicle_vin {
    color: #747373;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;

    span {
      font-weight: 700;
    }
  }

  .fleet_vehicle_img {
    width: 105px;
    height: 59px;
    object-fit: contain;
    background: white;
    border-radius: 5px;
    mix-blend-mode: multiply;
  }
}
