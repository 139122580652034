.reporting-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-top: 1em;

  .reporting-header {
    display: flex;
    width: 93vw;
    align-items: flex-start;
    gap: 20px;

    .reporting-header-left-section {
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      background: #fff;

      .reporting-type-section {
        display: flex;
        align-items: center;
        gap: 16px;

        .reporting-type-label {
          color: #292f36;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 111.111% */
        }

        .reporting-type-select-section {
          display: flex;
          width: 320px;
          height: 44px;
          justify-content: center;
          align-items: center;

          .select-box {
            display: flex;
            width: 320px;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            flex-shrink: 0;
          }
        }

        .configure-new-data-button {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 0px;
          border-radius: 8px;
          background: #292f36;
          cursor: pointer;

          .label-cover {
            display: flex;
            padding: 0px 4px;
            align-items: flex-start;
            gap: 0px;

            .label {
              color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
            }
          }
        }
      }
    }

    .reporting-header-right-section {
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      background: #fff;

      .history-header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        .saved-history-label {
          color: #3c4550;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px; /* 112.5% */
        }

        .all-history-link-section {
          display: flex;
          padding: 0px 8px;
          justify-content: center;
          align-items: center;
          gap: 0px;
          border-radius: 8px;

          .label {
            color: #e95832;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            text-decoration-line: underline;
          }
        }
      }

      .saved-reports-name-section {
        display: flex;
        align-items: center;
        gap: 16px;

        .saved-reports-total-section {
          display: flex;
          align-items: center;
          gap: 4px;

          .saved-reports-total-cover {
            display: flex;
            align-items: flex-start;
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 16px;
            background: #e2e5e9;
            mix-blend-mode: multiply;
          }

          .saved-reports-label {
            color: #3c4550;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 114.286% */
          }

          .saved-reports-count {
            color: #3c4550;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }
        .saved-reports-name {
          color: #627184;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          text-decoration-line: underline;
          padding-right: 10px;
        }
        .show-selected-report {
          display: inline;
          align-items: flex-start;
          padding: 2px 8px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 16px;
          background: #fdece8;
          mix-blend-mode: multiply;
        }
      }
    }
  }

  .reporting-main-content {
    display: flex;
    width: 93vw;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid #eaecf0;
    background: #fff;
    box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);

    .reporting-main-content-card-header {
      display: flex;
      padding: 20px 0;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      .card-header-left-section {
        display: flex;
        align-items: flex-start;
        gap: 20px;

        .summary-data-section {
          display: flex;
          width: 269px;
          height: 80px;
          padding: 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid #e2e5e9;
          background: #fff;

          .summary-data-label-cover {
            display: flex;
            align-items: center;
            gap: 13px;

            .label {
              color: #4c5866;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 111.111% */
              letter-spacing: -0.36px;
            }

            .show-up-percentage-section {
              display: flex;
              align-items: flex-start;
              mix-blend-mode: multiply;

              .show-up-percentage-cover {
                display: flex;
                padding: 2px 8px 2px 6px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 16px;
                background: #edf8f4;

                .up-arrow-icon {
                  width: 12px;
                  height: 12px;
                  margin-bottom: 5px;
                }

                .label {
                  color: #2d765e;
                  text-align: center;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 16px; /* 114.286% */
                }
              }
            }
          }

          .summary-data-amount {
            color: #292f36;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 108.333% */
            letter-spacing: -0.48px;
          }
        }
      }
    }

    .main-content {
      display: flex;
      align-items: flex-start;
      align-self: stretch;
      background: #fff;
    }
  }

  .reporting-side-modal-section {
    display: flex;
    width: 507px;
    height: 100vh;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: -5px 4px 20px 0px rgba(0, 0, 0, 0.08);
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
  }
  .reporting-main-content-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 79px;

    &.edit-filter {
      padding-bottom: 144px;
    }

    .reporting-basic-filter-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .select-box {
        width: -webkit-fill-available;
        width: -moz-available;
      }

      .report-name-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        align-self: stretch;

        .enter-report-text {
          color: #292f36;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }
      }
    }

    .vector {
      height: 0px;
      align-self: stretch;
      stroke-width: 1px;
      stroke: #a7b2be;
    }

    .report-date-text {
      color: #3c4550;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 111.111% */
    }
    .reporting-date-filter-radio-section {
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }

    .reporting-date-inputs-section {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .date-input {
        display: flex;
        align-items: center;
        gap: 12px;
        align-self: stretch;
      }

      .hyphen {
        color: #3c4550;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 112.5% */
      }

      // .report-date-picker {
      .react-datepicker-wrapper {
        width: 100%;
        .custom__datepicker-input {
          border: 1px solid #22242626;
          border-radius: 4px;
          padding: 8px;
          height: 48px !important;
          width: 100%;

          &:focus-visible,
          &:focus {
            outline: 0 solid #85b7d9 !important;
            border: 1px solid #85b7d9 !important;
          }
          .react-datepicker__month-select {
            padding: 5px 0;
            margin-top: 9px;
            border: 1px solid rgba(34, 36, 38, 0.15);
            background: white;
          }
        }

        .custom__datepicker-error {
          border: 1px solid #b91c1c !important;
        }
      }

      /* Custom styles for the month and year dropdowns */
      .react-datepicker__month-dropdown-container,
      .react-datepicker__year-dropdown-container {
        margin: 0 5px;

        select {
          padding: 5px;
          border: 1px solid #ccc;
          background-color: #f8f8f8;
          border-radius: 4px;
          color: #333;
          outline: none;
          margin-top: 12px;

          &:focus {
            border-color: #85b7d9;
          }
        }
      }
      // }
    }

    .reporting-cost-input-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .cost-section-wrap {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }
    }
  }

  .reporting-side-modal-button-frame {
    display: flex;
    width: 507px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    position: absolute;
    bottom: 1px;
    background: #fff;
    box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05);

    .run-data-button-section {
      display: flex;
      height: 48px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 0px;
      align-self: stretch;
      border-radius: 8px;
      background: #c4ccd4;
      cursor: not-allowed;

      .run-data-button-wrap {
        display: flex;
        padding: 0px 4px;
        align-items: flex-start;
        gap: 0px;

        .text {
          color: #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }
      }
    }

    .run-data-enable-button {
      border-radius: 8px;
      background: #292f36;
      cursor: pointer;
    }
  }
}

.reporting-header-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  .reporting-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex: 1 0 0;

    .reporting-header-frame {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 7px;
      align-self: stretch;

      .report-text {
        color: #3c4550;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 107.143% */
        letter-spacing: -0.56px;
      }

      .note {
        align-self: stretch;
        color: #627184;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 112.5% */
      }
    }
  }
}

.cross-icon-section {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: #f0f2f4;

  .cross-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}

.reporting-history-section {
  display: flex;
  width: 507px;
  height: 1121px;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: -5px 4px 20px 0px rgba(0, 0, 0, 0.08);
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;

  .report-history-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .history-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .report-name {
        color: #292f36;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 112.5% */
      }

      .reports-other-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;

        .details-text {
          color: #4c5866;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 112.5% */
        }
      }
    }

    .history-actions {
      display: flex;
      width: 166px;
      padding: 16px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      .icons {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      }
    }
  }
}

.MuiToolbar-root {
  display: contents;
}

.custom-pagination {
  display: flex;
  padding: 20px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid #c4ccd4;
}

.MuiPaper-root {
  width: 100%;
}

.MuiTableCell-head {
  padding: 0 !important;
}

.MuiTableCell-head span {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
}

.MuiTableCell-body {
  width: 13%;
}

.MuiTableCell-root {
  text-align: center !important;
}

.MuiButton-label div div {
  display: inline-block;
  z-index: 1;
  color: #3c4550;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.MuiTableCell-body div:nth-child(2) {
  color: #4c5866;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.ui.pagination.menu {
  box-shadow: none !important;
  border: none !important;
}

.ui.pagination.menu .active.item {
  background-color: #fdece8 !important;
}

.MuiToolbar-gutters {
  display: block !important;
}

.MuiToolbar-root {
  display: block !important;
}

.current-report-name {
  color: #3c4550;
  font-size: 16px;
  font-style: normal;
  line-height: 20px; /* 111.111% */
  letter-spacing: -0.36px;
  margin-top: 20px;

  .label {
    color: #627184;
  }
}

.opacity-1 {
  opacity: 1 !important;
}

.collapsable-service-column {
  text-align: left;
}

.collapsable-service-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.service-list-tooltip {
  text-align: left;
  list-style-type: square;
  list-style-position: inside;
}

.fleet-name-column {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.filter-tag {
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #e2e5e9;
  mix-blend-mode: multiply;
  display: inline-flex;
  text-wrap: nowrap;

  &-label {
    color: #627184;
  }

  &-item {
    display: inline-block;
    max-width: 15ch;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.filter-tag > .filter-tag-item:not(:last-child)::after {
  content: ",";
}

.filter-tag > :nth-last-child(1 of .filter-tag-item)::after {
  content: "";
}

.applied-filters {
  display: inline-flex;
  gap: 2px;
  overflow: hidden;
  align-items: center;
}

.card-header-right-section {
  display: flex;
  align-items: center;
  gap: 16px;

  .created-on-text-label {
    color: #627184;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
  }

  .clear-filter-link {
    cursor: pointer;
    color: $link-color;
    text-decoration: underline;
    text-wrap: nowrap;
  }

  .transaction-found-label {
    color: #292f36;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 111.111% */
  }

  .transaction-total-number {
    color: #292f36;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .reports-icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #e2e5e9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}

.reporting-secondary-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
  align-items: center;
}

.download-report-modal {
  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    background: $checkbox-background-color;
    border-radius: 3px;
    border: 1.5px solid $checkbox-background-color !important;
  }
  .ui.checkbox input:checked ~ .box:before,
  .ui.checkbox input:checked ~ label:before {
    color: white;
  }
}

.cross-icon {
  cursor: pointer;
}
